// WARNING, do not touch the double quote (") chars used in version, commitHash, and installationDate, it used by the CI/CD
export const envBase = {
  "czamApiUrl": "/czam-api",
  "restrictionsApiUrl": "/casino-restrictions-api",
  "casinoInjApiUrl": "/casino-inj-api",
  "swiftSaleApiUrl": "/swift-sale-api",
  "appsApiUrl": "/user-apps-api",
  "ocmConnectApiUrl": "/ocm-connect-api",
  "customerExtendedApiUrl": "/api-fiche-client",
  "casinoLoyaltyApiUrl": "/casino-loyalty-api",
  "walletApiUrl": "/wallet-api",
  "notificationCenterApiUrl": "/notifications-api",
  "hotelUnifiedDataApiUrl": "/hotel-unifieddata-api",
  "datascienceApiUrl": "/datascience-api",
  "version": "1.19.1.3020",
  "commitHash": "e032382230f37d4cf74e82d8bfc7bc21bb74d03b",
  "packageGenerationDate": "2024-10-10T13:45:38Z",
  "asyncClientEntry": false,
  "numberOfSearchChar": 4,
  daysBeforeSignalCardExpireSoon: 15
};
