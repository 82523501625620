import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import moment from 'moment';
import { PlaceRole, PlaceType } from '../models/casino-role.class';
import { SystemService } from './czam-api/system.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { Role } from "../enums/role.enum";
import * as Sentry from "@sentry/angular";

import { IAdditionalRoles } from "../interfaces/user-account-mgmt.interface";

export enum LogOutReason {
  User,
  MissingRole
}

export interface IAbstractClaimsRole {
  place_code: string;
  place_type: string;
}

export interface IClaimsRoles extends IAbstractClaimsRole {
  roles: string;
}
export interface IClaimsRole extends IAbstractClaimsRole {
  role: string;
}

const SWAGGER_ACCESS_TOKEN_NAME = "X-Swagger-Access-Token"
const LOGIN_ROUTE = "/home/login";


@Injectable({
  providedIn: 'root'
})
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  public user: ReplaySubject<User | null> = new ReplaySubject<User | null>(1);
  public isLoggedIn: ReplaySubject<boolean> = new ReplaySubject(1);

  private isAdmin: ReplaySubject<boolean> = new ReplaySubject(1);
  public isAdmin$: Observable<boolean> = this.isAdmin.asObservable();

  public roles: ReplaySubject<PlaceRole[] | null> = new ReplaySubject<PlaceRole[] | null>(1);
  public roles$: Observable<PlaceRole[] | null> = this.roles.asObservable();
  public restrictedCasinoCodeListForCurrentUser: string[] = [];
  public hasCasinoRole = false;
  public hasAccessToRestriction = false;

  private currentUserRoles: PlaceRole[] = [];
  private _currentUser: User | null = null;


  constructor(
    private router: Router,
    private systemService: SystemService,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private location: Location
  ) {
  }

  /**
   * Start the authentication process
   */
  init() {
    const auth = getAuth();
    onAuthStateChanged(auth, this.onAuthStateChanged);
  }

  /**
   * Navigate to login page
   */
  navigateToLogin() {
    // Skip the action if already on the login page !
    if (this.router.url.startsWith('/login')) {
      return
    }

    this.route.queryParams.subscribe(params => {

      let redirectUrl = document.querySelector('base')?.getAttribute('href') || '/';
      redirectUrl += this.location.path();

      if (params['redirectUrl']) {
        redirectUrl = params['redirectUrl'];
      }
      window.location.href = LOGIN_ROUTE + '?redirectUrl=' + redirectUrl;
    });

  }

  /**
   * Called when the authentication status changes
   * @param user
   */
  onAuthStateChanged = async (user: User | null) => {
    if (user) {

      Sentry.setUser({ email: user.email ?? 'unknown' });

      await this.onSignIn(user);
    } else {
      Sentry.setUser(null);
      this.onSignOut();
    }
  }

  private async onSignIn(user: User) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    this._currentUser = user;

    this.user.next(user);

    const tokenResult = await user.getIdTokenResult();

    // Set the token as a cookie, used for swagger authentication
    // TODO: do this only when comming from swagger ?
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1); // Set it to expire in one year
    document.cookie = `${SWAGGER_ACCESS_TOKEN_NAME}=${await user.getIdToken()}; expires=${date.toUTCString()}; path=/; secure; SameSite=Strict`;


    const systemInfo = await firstValueFrom(this.systemService.getParameters());
    let expirationMoment = moment(new Date(tokenResult.expirationTime));
    let nowMoment = moment(new Date()); //todays date
    let expirationInMs = moment.duration(expirationMoment.diff(nowMoment)).asMilliseconds();

    // !!!required casino set!!!
    this.setRoleAndAvailableCasinosFromClaims(tokenResult.claims);

    if (!this.isAdmin && this.restrictedCasinoCodeListForCurrentUser.length == 0) {
      await this.logout(LogOutReason.MissingRole);
      return;
    }

    console.log('claims', tokenResult.claims);
    console.log("User is logged in", user);

    this.isLoggedIn.next(true);
  }


  /**
   * Called when the user signs out
   */
  private onSignOut() {

    // remove user from local storage to log user out
    LocalStorageService.delete(LocalStorageService.SETTINGS);

    document.cookie = `${SWAGGER_ACCESS_TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    // User is signed out
    this.user.next(null);
    this.isLoggedIn.next(false);
    this.isAdmin.next(false);
    this.roles.next(null);
    this.isLoggedIn.next(false);

    this.navigateToLogin();
  }

  /**
   * Set roles and available casino from the claims
   * @param claims
   * @returns
   */
  setRoleAndAvailableCasinosFromClaims(claims: any) {

    // For testing
    /*
    this.roles.next([]);
    return [];*/

    if (!('glbr_role' in claims) && !('glbr_roles_ext' in claims)) {
      this.roles.next([]);
      return;
    }

    // RCPT test !
    /*claims.glbr_role.place_type = "casino";
    claims.glbr_role.role = 'CVH'
    claims.glbr_additional_roles = [];*/


    // NDR test !
    /*claims.glbr_role.place_code = "NDR";
    claims.glbr_role.place_type = "Casino";
    claims.glbr_role.role = 'VDI'
    claims.glbr_additional_roles = [];*/

    // Get ext roles from claims
    let glbrRolesExt: IClaimsRoles[] = claims.glbr_roles_ext ?? [];

    // For each role in the extension we split the roles to create one object for each role
    let roleListFromGlbrRolesExt = (glbrRolesExt.map((item: IClaimsRoles) => {
      return item.roles.split(',').map((role: string) => {
        return {
          role: role,
          place_code: item.place_code,
          place_type: item.place_type
        } as IClaimsRole
      }) as IClaimsRole[]
    }) as IClaimsRole[][]).reduce((accumulator: IClaimsRole[], currentValue: IClaimsRole[]) => [...accumulator, ...currentValue], []);

    // Concat all roles in one table
    let roles = [claims.glbr_role, ...claims.glbr_additional_roles, ...roleListFromGlbrRolesExt];

    // Send information in sentry context
    Sentry.setContext("roles", {
      main: claims.glbr_role,
      extented: roles,
      additional: claims.glbr_additional_roles
    });

    // TODO: We manualy filter out RCPT and CVH roles because the place_type hotel is not available in the claims yet
    roles = roles.filter(role => (role.place_type.toLowerCase() == "casino" || role.role == 'ADMIN'))
      .map(role => {
        if (role.role == Role.RCPT || role.role == Role.CVH) {
          return new PlaceRole(role.role, '*', PlaceType.Hotel);
        }
        return new PlaceRole(role.role, role.place_code, PlaceType.Casino);
      }) || [];


    // For testing
    /*roles = [
      new CasinoRole('MCD', 'LAB'),
      new CasinoRole('DIR_CASINO', 'LAB')
    ];*/
    //roles.push(new CasinoRole('ADMIN', ''));

    // TODO: Update this logic when we have other role types
    this.hasCasinoRole = roles.length > 0;

    this.currentUserRoles = roles;
    this.roles.next(roles);
    this.isAdmin.next(roles.some(role => role.role == 'ADMIN'));

    this.checkAccessToRestriction();

    var restrictedCasinoCodeListForCurrentUser = roles.filter(g => g.code != null).map((role: PlaceRole) => {
      return <string>role.code;
    });

    this.restrictedCasinoCodeListForCurrentUser = restrictedCasinoCodeListForCurrentUser;

    return roles;
  }

  /**
   * Authenticate with google
   */
  async loginWithGoogleAsync() {
    const provider = new GoogleAuthProvider();

    const auth = getAuth();
    let result = await signInWithPopup(auth, provider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);

    if (credential == null) {
      throw ("Could not get credentials")
    }
  }

  /**
   * Authenticate with login and password
   * @param login
   * @param password
   */
  async login(login: string, password: string) {
    const auth = getAuth();

    let credentials = await signInWithEmailAndPassword(auth, login, password);
    if (credentials == null) {
      throw ("Could not get credentials")
    }
  }

  /**
   * Call to reset the user's password
   * @param email
   */
  async forgottenPassword(email: string) {

    const auth = getAuth();
    await sendPasswordResetEmail(auth, email)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Cannot send password reset: ${errorCode}, ${errorMessage}`)
      });

    // reset sent
  }

  /**
   * Logout
   */
  async logout(reason: LogOutReason = LogOutReason.User): Promise<void> {
    const auth = getAuth();
    await signOut(auth);
  }

  /**
   * Returns the current jwt token
   * @returns
   */
  async getJwtToken(): Promise<string | null> {

    if (this._currentUser) {
      return await this._currentUser.getIdToken();
    }
    return null;
  }

  /**
   * Returns the current connected user's username
   * @returns
   */
  getUsername(): Observable<string> {
    return this.user.pipe(map((user) => user?.displayName ?? ""))
  }

  /**
   * Check if the user has the right role to access the restrictions page
   */
  checkAccessToRestriction() {
    let hasAccess = false;
    this.currentUserRoles.forEach(casinoRole => {
      if (casinoRole.role == Role.DIR_CASINO || casinoRole.role == Role.DIR_JEUX || casinoRole.role == Role.MCD ||
        casinoRole.role == Role.ADMIN) {
        hasAccess = true;
      }
    })

    this.hasAccessToRestriction = hasAccess;
  }

  getUser() {
    return this._currentUser;
  }
}

